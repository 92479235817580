/***************************
 * MAIN DEFAULT STYLES
 ***************************/

html, body {
    height: 100%;
    font-weight: 300;
    min-width: @container-large-desktop;
}

.app-content {
	height: 100%;
	display: none;
}

.main {
    min-height: calc(100% - @footer-height);
    padding-top: (@navbar-height + 10px);
    position: relative;
}

//BOOTSTRAP OVERRIDES

b, strong {
    font-weight: 500;
}

dt {
	font-weight: 500;
}

.dropdown-menu > li > a {
    font-weight: 300;
}

.navbar-nav > li > .dropdown-menu {
	min-width: 100%;
}

.input-group-btn .btn-sm {
	border-radius: @border-radius-base;
}

// Correct Handling for FeedbackIcons  in Small Forms
.form-group-sm {
	&.has-feedback {
		.form-control {
			padding-right: (@input-height-small);
		}

		.form-control-feedback {
			width: @input-height-small;
			height: @input-height-small;
			line-height: @input-height-small;
		}
	}
}

a.list-group-item {
	&:hover, &:active, &:focus {
		color: @brand-primary;
	}
}

// COMMON STYLES

.page-title {
	padding: 10px;
	color: @brand-primary; //@white;
	letter-spacing: 1px;
	//background-color: @brand-primary;
	//border-radius: @border-radius-large;
	//.box-shadow(@shadow-default-small);

}

.list-group-item {
	.listLabel {
		font-size: (@font-size-h4*0.9);
		letter-spacing: 1px;
		vertical-align: middle;
	}

	.fa-stack-1x,
	.fa-stack-2x {
		top: -5%;
	}

	.fa-stack-2x.info {
		color: @brand-primary;
	}

	.fa-stack-1x.text {
		font-family: @font-family-base;
	}
}

// Status Icon Styling
.status-icon {
    display: inline-block;
    color: @gray-light-high;
    position: relative;

    .transition(color cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s);

    &.active {
	    &.fatal {
            color: @brand-danger;
        }
        &.error {
            color: @brand-danger;
        }
        &.warning {
            color: @brand-warning;
        }
        &.info {
            color: @brand-primary;
        }
        &.ok {
            color: @brand-success;
        }

        &.validating {
            color: @brand-info;
        }

        &.edit {
            color: @brand-info;
        }

        &.new {
            color: @brand-info;
        }
        &.deleted {
            color: @gray-light-high;
        }
    }
}

.progress {
    margin: 0;
    padding: @border-radius-small;
    border-radius: @border-radius-large;

    .progress-bar {
        max-width: 100%;
        border-radius: @border-radius-large;
        font-size: 11px;
        line-height: (@line-height-small + 0.1);

        span {
            display: inline-block;
        }
    }
}

.section-heading {
    border-bottom: 2px solid @gray-light;
    position: relative;
    margin-top: 45px;
    margin-bottom: 25px;
}

.section-heading-text,
.section-status {
	color: white;
    background-color: @gray-light;
    border-radius: @border-radius-large;
    margin: 0;
}

.section-heading-text {
    display: inline-block;
    letter-spacing: 1px;
    padding: 5px 15px;
    font-size: @font-size-h4;

    position: absolute;
    bottom: -15px;

    a {
	    color: white;

	    i {
		    color: white;
		    margin-left: -(@border-radius-large - 5px);
            .transition(0.34s ease);
	    }

	    &:hover {
		    color: @brand-primary-light;
	    }
    }

    .tooltip-inner {
        max-width: none;

        table {
            min-width: 260px;
            margin: 0;
            padding: 0;
        }
    }

}

.section-status {
	padding: 5px;

	position: absolute;
    bottom: -15px;
    right: 0;
    left: auto;

    .status-icon {
    	color: white;
    }
}

.section-info {
	margin: -21px 0px 10px 0px;
	font-size: 80%;
	text-align: right;
	color: @gray-light;
	font-style: italic;
}

// Date Picker Styling
ul.dropdown-menu[datepicker-popup-wrap] {
    table {
        th {
            padding: 0px 5px 5px 5px;
            text-align: center;
            color: @gray-light-mid;
            font-weight: 300;
            font-size: 90%;
            border-bottom: 1px solid @gray-light;

            &.text-center {
	            padding: 5px;
            }

            .btn {
                font-weight: 300;
            }
        }

        td {
            padding: 5px;
            border-top: 0 none;
        }
    }

	.bottom-btn-bar {
		border-top: 1px solid @gray-light;
	}

	.btn {
		&.disabled,
		&[disabled] {
		    &,
		    &:hover,
		    &:focus,
		    &.focus,
		    &:active {
				color: @gray-light;
		  	}
		}
	}

	.date-secondary {
		.opacity(0.6);
	}

}

.panel-main {
    border: 0 none;
    .box-shadow(none);
    padding-top: 50px;
    position: relative;

    > .panel-heading {
        letter-spacing: 1px;
        font-size: ceil((@font-size-base * 1.5));

        display: inline-block;

        border-top-left-radius: @border-radius-large;
        border-top-right-radius: @border-radius-large;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        position: absolute;
        top: 14px;
        left: 15px;

        padding: 5px 15px 2px;

		&.tab-group {
			background-color: transparent;
			padding: 0;

			> .btn {
				border-top-left-radius: @border-radius-large;
		        border-top-right-radius: @border-radius-large;
		        border-bottom-left-radius: 0;
		        border-bottom-right-radius: 0;

		        border: 0 none;

		        background-color: darken(@brand-primary, 10%);;

		        font-size: ceil((@font-size-base * 1.5));

		        padding: 5px 15px 3px;

		        &.active, &:hover, &:focus, &:active {
			        background-color: @brand-primary;
		        }
			}
		}
    }

    > .panel-body {
	    border-radius: @border-radius-large;
        border-top: 3px solid @brand-primary;
        padding-top: 0;
        .box-shadow(@shadow-default);

        &.tab-group {
	        padding-top: @panel-body-padding;
        }

        > .panel-footer {
	        padding: 5px 0;
			background-color: transparent;
			border-top: 2px solid @gray-light;
			border-radius: 0;
			margin-bottom: -10px;
			margin-top: 15px;
		}
    }
}

.panel-table {
    > .panel-body {
        padding: 0;
    }
}

.table-clickable {
	tbody > tr {
		cursor: pointer;
	}
}

table {
	& > tbody > tr > td.table-menu {
        text-align: right;
        padding: 0;

        .btn {
            border-left: 1px solid @gray-light-high;
        }

        .dropdown-menu {
            right: 0;
            left: auto;
        }
    }
}

.table-light {
    border: 1px solid @gray-lighter;

    thead > tr > th {
        padding: 3px 5px;
        color: @gray-light-mid;
        font-weight: 300;
        font-size: 90%;
        border-bottom: 1px solid @gray-light;
    }

    tbody > tr {

    	> td {
	        vertical-align: middle;
	        padding: 3px 5px;
	        border-top: 1px dashed @gray-light;
        }

        &.inactive > td {
	        color: @gray-light;

	        .progress-bar {
                opacity: 0.5;
            }

            .occupancy-comment-icon {
	            opacity: 0.5;
            }

            .progress-bar {
	            background-color: @gray-light;
            }
        }

        &.revoked > td {
            text-decoration: line-through;

            .progress-bar span {
	            text-decoration: line-through;
            }
        }
    }
}

.table-occupancy {

    th {
        text-align: center;
    }

    td {
        text-align: center;

        &.table-menu {
            text-align: right;
            padding: 0;
        }
    }

    .occupancy-status {
        text-align: left;

        .occupancy-comment-icon {
            display: inline-block;
            position: relative;
        }

        table.occupancy-status-popup-table {
            /* as this table is embedded into the table displaying the occupancy, we have to override the properties defined there */
            margin-bottom: 0;
            padding: 0;
            background-color: inherit;
            min-width: 250px;

            th {
                text-align: left;
                padding-top: 0;
                padding-bottom: 0;
                color: white;
            }

            td {
                border-top-style: solid;
                white-space: nowrap;
            }

            td.labels {
                text-align: left;
            }

            td.values {
                text-align: right;
            }

        }

    }

    .occupancy-doc-num {
    }

    .occupancy-doc-name {
    }

    .occupancy-date-from {
    }

    .occupancy-date-to {

    }

    .occupancy-allocation {
        .progress {
            margin: 0px;

            overflow: visible;

            .progress-bar {
                position: relative;
            }
        }
    }

    .occupancy-date-modified {

    }

    .occupancy-menu {

    }

    tfoot {
        > tr > td {
            //border-top: 1px solid @gray-lighter;
            padding: 0px;
            text-align: left;
            min-height: 30px;

            .btn {
                margin: 0;
                padding: 6px 8px;
                color: @brand-primary;
                border-color: @gray-lighter;
                border-width: 0px 1px 0px 0px;
                border-radius: 0;

                background-color: transparent;
                .box-shadow(none);

                &:hover, &:focus {
                    background-color: @list-group-hover-bg;
                    color: @brand-secondary;
                }

                .btn-title {
                    padding-left: 5px;
                }

                &.left {
                    float:left;
                }
                &.right {
                    float:right;
                }
            }
        }
    }

}

// Drag&Drop FileUpload Styling
.drop-box {
	display: table;
	background: @gray-lighter;
	border: 3px dashed @gray-light;
	width: 100%;
	min-height: 145px;
	text-align: center;
	padding: 10px;

	color: @gray;


	cursor: pointer;

	.drop-box-content {
		display: table-cell;
		vertical-align: middle;
		height: 100%;
	}

	.info-icon {
		margin-bottom: 10px;

		&:before {
			content: '\f016';
		}
	}

	.info-text {
		font-size: 85%;
		color: @gray-light;
		display: block;
	}

	.info-text-error-file-type,
	.info-text-error-file-size {
		display: none;
	}

	.info-file {
		font-weight: 500;
		display: block;
		margin-bottom: 10px;
	}

	&.dragover,
	&.file-ok {

		.info-icon {
			color: @brand-success;
			&:before {
			content: '\f05d';
			}
		}

		.info-text {
			display: none;
		}
	}

	&.dragover-error,
	&.file-type-error,
	&.file-size-error {

		.info-icon {
			color: @brand-danger;
			&:before {
				content: '\f05c';
			}
		}

		.info-text {
			display: none;
		}
	}

	&.file-type-error {
		.info-text-error-file-type {
			display: block;
		}
	}
	&.file-size-error {
		.info-text-error-file-size {
			display: block;
		}
	}

	&.dragover-error {
		.info-file {
			display: none;
		}
	}
}


// Container for Overlay
.activity-overlay-container {
	position: relative;
	min-height: 6em;
}

.activity-overlay-container-inline {
	position: relative;
	min-height: 4em;
}

// Loading Indicator Overlay for Elements
.activity-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: (@zindex-navbar - 10);
  background-color: fade(#fff, 60%);

  .overlay-status-wrap {
	  position: absolute;
	  top: 50%;
	  width: 100%;
	  margin-top: -2.5em;
  }

  .overlay-status-icon {
	  display: block;
	  margin: 0 auto;
	  color: @brand-primary;
	  background-color: #fff;
	  padding: 0.4em;
	  border-radius: 50%;
	  width: 2.8em;
	  height: 2.8em;
	  .box-shadow(@shadow-default-small);
  }

  .overlay-status-text {
	  text-align: center;
	  display: block;
	  color: @gray-light-mid;
	  font-size: 1.5em;
	  letter-spacing: 1px;
	  padding: 0.25em;
  }

  &.inline {
	  .overlay-status-wrap {
		  position: absolute;
		  top: 50%;
		  width: 100%;
		  margin-top: -1.5em;
	  }
	 .overlay-status-icon {
		  color: @gray-light-mid;
		  background-color: transparent;
		  .box-shadow(none);
	  }
  }
}

// Loading Indicator Styling for Inputs
.form-group.is-loading .form-control-feedback {
	color: @gray-light-mid;
}

.btn {
	.transition(0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) all);

	.btn-title {
		display: inline-block;
		position: relative;
		z-index: 3;
	}
	.btn-feedback {
		.fa-icon();
		position: absolute;
		z-index: 2;

		display: inline-block;
		width: 1.5em;
		height: 1.5em;
		line-height: 1.5em;

		top: 50%;
		right: 0.4em;
		margin-top: -0.8em;
		opacity: 0;
		pointer-events: none;

		text-align: center;
		background-color: fade(#fff, 55%);
		border-radius: 50%;
		margin-left: 0;

		.transition(0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) opacity);

		&:before {
			position: relative;
		}
	}

	&.has-feedback {

		&.expand {
			padding-right: 2.4em;
		}

		.btn-feedback {
			opacity: 1;
		}

		&.loading {
			opacity: 0.65;

			.btn-feedback {
				color: fade(@brand-info,75%);

				&:before {
					-webkit-animation: fa-spin 2s infinite linear;
					animation: fa-spin 2s infinite linear;
					content: @fa-var-circle-o-notch;
				}
			}
		}

		&.ok {
			.btn-feedback {
				color: fade(@brand-success,75%);

				&:before {
					content: @fa-var-check;
				}
			}
		}

		&.error {
			.btn-feedback {
				color: fade(@brand-danger,75%);

				&:before {
					content: @fa-var-times;
				}
			}
		}
	}
}