.overview {
	padding-top: (@toolbar-height + 5px);

	&.has-toolbar {
		padding-top: (@toolbar-height + 25px);
	}

    .panel-accordion {
        border: 0px none;
        .box-shadow(none);
        border-radius: @border-radius-base;
        //overflow: hidden;

        > .panel-heading {
            padding: 0px 0px;
            background-color: @gray-lighter;
        } //.panel-heading

        .panel-collapse {
	        > .panel-body {
            	padding: 0;
            }
        } //.panel-body
    }

    .accordion-header {
        margin-bottom: 0;
        background-color: transparent;

        td {
            background-color: transparent;
            padding: 0px 5px 0px 5px;
            vertical-align: middle;
            border: 0 none;
            //text-align: center;

            &.table-menu {
	            padding-right: 0;
            }
        }

        .trainingPost-name {
            width: 39%;
            text-align: left;
            font-weight: normal;
        }
        .trainingPost-occupants {
            width:  29%;
            text-align: left;
            font-size: @font-size-base;
            color: @gray-light;

            .occupant {
	            display: inline-block;
	            //padding-right: 15px;
	            font-size: 90%;

	            i {
		            font-size: @font-size-base;
	            }

            }
            //font-weight: normal;

            .draft {
                color: @brand-primary;
            }
        }
        .trainingPost-recognition {
			width: 4%;
            text-align: right;
            font-size: @font-size-base;
			color: @gray-light;
			
			.recognition {
				display: inline-block;
	            font-size: 90%;

	            i {
		            font-size: @font-size-base;
	            }
			}
		}
        .trainingPost-occupants-divider {
	        display: inline-block;
	        padding: 0 10px;
        }
        .trainingPost-allocation {
            width: 11%;
        }
        .trainingPost-status {
	        text-align: center;
            width: 13%;
        }
        .trainingPost-menu {
            width: 4%;
        }

        .accordion-toggle {
            display: block;
            text-decoration: none;

            i {
                .transition(0.34s ease);
            }
        }

        .trainingPost-status-icon {
            //min-width: 30px;

            .fa-lg {
                font-size: 115%;
            }

            > .small.badge {
                opacity: 0.7;
                background-color: @gray-light-mid;
                position: absolute;
                top: -3px;
                right: -2px;
                font-size: 9px;
                min-width: 5px;
                padding: 3px 4px 1px 4px;
                font-weight: 300;
                display: none;
            }

            &.active {
                > .small.badge {
                    display: inline-block;
                }
            }
        }
    }

    // Disabled Styles
    .panel-accordion.inactive {
	    > .panel-heading {
            background-color: @panel-default-heading-bg;
        } //.panel-heading

        .panel-collapse {
	        > .panel-body {
            	table {
	            	background-color: @panel-default-heading-bg;
            	}
            }
        } //.panel-body

	    .accordion-header {
		    .trainingPost-name {
			    text-decoration: line-through;
		    }

		    .trainingPost-name,
	        .trainingPost-occupants,
	        .trainingPost-occupants-divider,
	        .trainingPost-allocation,
	        .trainingPost-status,
	        .trainingPost-menu .dropdown-toggle {
		        opacity: 0.5;
	        }
		}
    }

    .table-occupancy {

        .occupancy-status {
            width: 10%;

			// Tooltip Class for showing Errors
            asv-occupancy-status .tooltip {

                opacity: @tooltip-opacity;

                .tooltip-inner {
					padding: 0;
					max-width: none;
				}

				.list-group {
					margin-bottom: 0;
					max-width: 200px;

					&.list-group-medium {
						max-width: 300px;
					}

					&.list-group-large {
						max-width: 400px;
					}
				}

				.list-group-item {
					padding: 5px 5px 5px 30px;
					background-color: transparent;
					color: white;
					border-width: 0px 0px 1px 0px;
					text-align: left;

					&:last-child {
						border-width: 0;
					}

					.fa.pull-left {
						top: 50%;
						position: absolute;
						left: 5px;
						line-height: 0;
                    }
                    
                    table.occupancy-status-popup-table {
                        background-color: inherit;
                    }
				}

		    }

            .occupancy-comment-icon {
	            .tooltip {
		            opacity: @tooltip-opacity;

		            left: calc(50% - @tooltip-arrow-width - @border-radius-base);
		            bottom: calc(100% - @tooltip-arrow-width);
	            }

	            .tooltip-inner {
		            max-width: none;
		            min-width: 140px;
		            padding: 3px;

		            .form-control {
			            background-color: transparent;
			            color: @tooltip-color;
			            border-color: @gray;
		            }
	            }

	            a.inline-edit-link {
                  &:hover, &:active, &:focus {
	                  color: @brand-primary-light;
                  }
                }
            }
        }

        .occupancy-doc-num {
            width: 10%;
        }

        .occupancy-doc-name {
            width: 26%;
        }

        .occupancy-date-from {
            width: 10%;
        }

        .occupancy-date-to {
            width: 12%;

            input {
	            max-width: 100px;
            }
        }

        .occupancy-allocation {
            width: 12%;

            .progress {
	            .tooltip {
		            left: calc(50% - @tooltip-arrow-width - 65px);
		            top: calc(100% - @tooltip-arrow-width);
	            }

	            .tooltip-inner {
		            max-width: none;
		            min-width: 130px;
	            }

	            a.inline-edit-link {
                  &:hover, &:active, &:focus {
	                  color: @brand-primary-light;
                  }
                }
            }
        }

        .occupancy-date-modified {
            width: 15%;
        }

        .occupancy-menu {
            width: 5%;
        }
    }


}

.new-occupancy-modal {
	.radio input[type="radio"] {
		margin-left: 0;
	}

	.form-group-radio {
		margin-bottom: 5px;
	}
}


.allocation-selector-popup {
    position:absolute;
    background-color: @dropdown-bg;
	border: 1px solid @dropdown-fallback-border; // IE8 fallback
	border: 1px solid @dropdown-border;
	border-radius: @border-radius-base;
	.box-shadow(0 6px 12px rgba(0,0,0,.175));
	background-clip: padding-box;

    right: 0;
    top: 31px;
    z-index: @zindex-dropdown;
    padding: 5px;
    width: 645px;
    height: 215px;
    font-size: 12px;

    .info-box {
	    float:left;
	    height: 100%;
	    width: 300px;
	    margin-right: 10px;

	    text-align: left;
	    white-space: normal;

		padding:15px 10px 15px 10px;
	    //padding-top: 15px;
	    //padding-bottom: 15px;
    }

    table {
	    float:left;
	    width: 320px;
	    height: 100%;
    }

    thead > tr > th {
        padding: 2px;
		color: @text-color;
		font-weight: 500;
		font-size: inherit;
		border-bottom: 0px none;
		text-align: center;
    }

    tbody > tr > td {
        padding: 2px;
		border: none;
    }

    .hour-btn {
        width: 100%;
        padding: 3px 6px;
        line-height: 1.5;
        border-radius: 2px;
    }

    .minute-btn {
        width: 100%;
        padding: 3px 6px;
        line-height: 1.5;
        border-radius: 2px;
    }

    .hours-input {
        padding: 2px 2px;
        width: 50px;
        font-weight: bold;
    }

    .percent {
        font-weight: bold;
        text-align: left;
    }

    .buttons {
        text-align: right;
        vertical-align: bottom;
    }

    .header {
        text-align: center;
        border-bottom: 1px solid black;
        margin: 2px -5px 5px -5px;
    }

    .footer {
        background-color: inherit;
    }

	.selected {
        @border-color: @gray;
        @shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 6px lighten(@border-color, 20%);
        .box-shadow(@shadow);
	}

    .selector {

        input.form-control {
            display: block;
            width: 33px;
            float: none;
            border-radius: 5px !important;
            padding: 5px 9px;
            margin: 5px 0;
        }

        .btn-sm {
            display: block;
            margin: 5px 0;
        }

    }
}

.split-occupancy-modal {

    h2 {
        font-weight: bold;
    }

    .sumEqual {
        color: #569942;
    }

    .sumNotEqual {
        color: #a04843;
    }


	.split-table-separator {
      color: @brand-primary;
	  text-align: center;
      font-size: 3.0em;
      margin: 7px 0px;
      opacity: 0.4;
	}

	.table-legend {
      color: @brand-primary;
	  text-align: right;
	  font-weight: 600;
	}

	.table-legend-border {
	  border-left: 2px solid @brand-primary;
	}

    table.occupancy-table {
        > thead > tr > th {
            padding: 4px 10px;
            border-bottom: 1px solid #cccccc;
        }

        > tbody > tr > td {
            padding: 4px 10px;
        }

        .occupancy-col1 {
            width: 10%;
        }

        .occupancy-col2 {
            width: 30%;
        }

        .occupancy-col3 {
            width: 15%;
        }

        .occupancy-col4 {
            width: 15%;
        }

        .occupancy-col5 {
            width: 20%;
        }

    }

    table.split-table {

        > thead > tr > th {
            padding: 4px 10px;
            border-bottom: 1px solid #cccccc;
        }

        > tbody > tr > td {
            padding: 4px 10px;

            > .input-group {
                width: 90px;
            }

        }

        .split-table-col1 {
            width: 10%;
        }

        .split-table-col2 {
            width: 30%;

            .new-occupancy {
                width: 360px;
                color: #888888;
            }

            .nothing {
                width: 360px;
                color: #eeeeee;
            }

            .original-changed {
                width: 360px;
                color: #000000;
            }

            .original-unchanged {
                width: 360px;
                color: #000000;
            }
        }

        .split-table-col3 {
            width: 20%;
        }

        .split-table-col4 {
            width: 10%;
        }

        .split-table-col5 {
            width: 10%;
        }



    }

}
