.login {
    position: relative;
    height: 100%;

    .login-container{
        width: 400px;

		margin: 80px auto;

        animation-duration: 1.5s;
        animation-name: fade-in;
    }

    .info-container{
        width: 460px;

		margin: 5px auto;

        animation-duration: 1.5s;
        animation-name: fade-in;
    }

    .container-box {
        position: relative;
        padding: 20px 30px 20px;
        text-align: center;
        background: #fff;
        border: 1px solid @input-border;

        z-index: 20;

        border-radius: @border-radius-base;

        .box-shadow(0px 2px 5px @gray-light);

        &.info-box {
	        background: @brand-info;
        }
    }

    #output{
        position: relative;
        margin: 0 5px;
        border: 0 none;
        border-radius: 0 0 @border-radius-base @border-radius-base;
        //margin-top: -80px;
        color: #fff;
        text-align: center;



        opacity: 1;
        .translate(0px; 0px);
			//-webkit-transform: translateY(0);
			//transform: translateY(0);

        z-index: 10;

        .box-shadow(0px 1px 5px @gray-light);

        &.alert-success{
            background: @alert-success-text;
        }

        &.alert-danger{
            background: @alert-danger-text;
        }

        &.ng-hide-add.ng-hide-add-active,
		&.ng-hide-remove.ng-hide-remove-active {
		  -webkit-transition: all linear 0.5s;
		  transition: all linear 0.5s;
		}

		&.ng-hide {
			opacity: 0;
			.translate(0px; -100px);
			//-webkit-transform: translateY(-100px);
			//transform: translateY(-100px);

		}
    }

    .logo{
        width: 100px;
        height: 100px;
        margin: 10px auto 30px;
        background-size: contain;
        background-image: url("../images/oeaek_logo.png");
        background-position: center center;
        background-repeat: no-repeat;
    }

    .form-box {
        input {
            width: 100%;
            text-align: center;
            height:40px;
            border: 1px solid @input-border;
            background: @gray-lightest;

            .transition(0.2s ease-in-out);

            &:focus {
                outline: 0;
                background: @brand-primary-accent;
            }

            &[type="text"], &[type="email"] {
                border-radius: @border-radius-base @border-radius-base 0 0;
                //text-transform: lowercase;
            }

            &[type="password"] {
                border-radius: 0 0 @border-radius-base @border-radius-base;
                border-top: 0;
            }
        }

        button.login {
            margin-top:15px;
            margin-bottom: 10px;
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0.0;
    }

    to {
        opacity: 1.0;
    }
}
