.trainingPostApplication {
	label {
		display: block;
		min-height: (2*@line-height-computed);
		vertical-align: bottom;

	}

	.form-group {
		margin-top: 25px;
		margin-bottom: 25px;
	}

	.content-box {
		padding-left: 30px;
		padding-right: 30px;
	}
}