.system-messages {
	position: absolute;
	z-index: @zindex-navbar-fixed;
	top: (@navbar-height + 1px);
	left: 0px;
	right: 0px;

	.alert {
		position: relative;

		border-radius: 0;

		.box-shadow(@shadow-default-small);

		&.alert-success {
			.alert-count {
				background-color: @state-success-text;
			}
		}

		&.alert-info {
			.alert-count {
				background-color: @state-info-text;
			}
		}

		&.alert-warning {
			.alert-count {
				background-color: @state-warning-text;
			}
		}

		&.alert-danger {
			.alert-count {
				background-color: @state-danger-text;
			}
		}
	}

	.alert-dismissible {
		.close {
			position: absolute;
			top: 5px;
			right: 7px;
		}
	}

	.alert-count {
		font-size: @font-size-base;
		font-weight: 500;
		line-height: 1.2;

		position: absolute;
		top: 50%;
		left: 15px;
		margin-top: -0.8em;
	}
	.alert-text {
		margin-left: 45px;
	}
}