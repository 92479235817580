
.toolbar {
    top: (@navbar-height + 1px);
    min-height: @toolbar-height;
    .box-shadow(@shadow-default);

    z-index: @zindex-navbar;

    &.no-shadow {
	    .box-shadow(none);
    }

    .navbar-nav {
        margin-left: -15px;

        > li {
            border-color: @gray-light;
            border-width: 0px 1px 0px 0px;
            //border-radius: 0;
            border-style: solid;

            &:first-child {
               border-width: 0px 1px 0px 1px;
            }

            > a {
                padding: @toolbar-padding-vertical floor((@grid-gutter-width / 3));
            }
        }
    }

    .navbar-nav > li > .dropdown-filter {
        color: @navbar-inverse-color;
        background-color: @navbar-inverse-bg;
        border-color: @navbar-inverse-border;

        min-width: 500px;
        max-width: 500px;
        padding: 0;
        margin: 0;

        label {
            font-weight: 300;
        }

        legend {
            color: @navbar-inverse-color;
            border-color: @navbar-inverse-color;
            //padding-bottom: 10px;
            margin-bottom: 10px;
            font-size: @font-size-h5;
            font-weight: 500;
            text-transform: uppercase;
        }

        fieldset {
            margin: 25px 10px;

            &.filter-bottom {
                margin: 30px 0px 0px 0px;
                border-top: 1px solid @navbar-inverse-color;
            }
        }

        .form-group {
            margin-bottom: 0;
        }

        .form-horizontal .has-feedback .form-control-feedback {
            right: 0px;
        }

        .form-control-feedback {
            pointer-events: auto;
        }

        .has-feedback .form-control {
            padding-right: 35px;
        }
    }

    .navbar-form {
        margin-top: 6px;
        margin-bottom: 6px;

        .input-group > .form-control {
            height: 28px;
            padding: 2px 6px;
        }

        .input-group-btn > .btn {
            padding: 3px 6px;
        }
    }

    .status-icon {
        //display: inline-block;
        padding: @toolbar-padding-vertical floor((@grid-gutter-width / 5));
        //color: @navbar-inverse-link-color;

        &:first-child {
            padding-left: (2* floor((@grid-gutter-width / 5)));
        }

        &:last-child {
            padding-right: (2* floor((@grid-gutter-width / 5)));
        }

    }

    .toolbar-label {
	    margin: .6em .4em 0 0;
    }

    .fa-stack {
    	margin-top: -0.7em;
		margin-bottom: -0.5em;
	}
}

.toolbar-label-block {
    margin-left: -15px;
    margin-right: -15px;
}

.toolbar-btn-link {
    color: @navbar-inverse-link-color;
    text-decoration: none;
    font-weight: 300;
    font-size: 95%;

    &:hover, &:active, &:focus {
	    color: @navbar-inverse-link-hover-color;
	    text-decoration: none;
    }
}

.toolbar-label {
    display: inline-block;
    font-weight: 300;
    font-size: 90%;
    color: @navbar-inverse-bg;
	padding: .3em .5em .25em;
    border: 1px solid @gray;
    border-radius: @border-radius-large;
    background-color: @navbar-inverse-color;

    a {
        color: @link-hover-color;
        padding-bottom: 0.1em;
        display: inline-block;

        &:hover,
        &:active {
            color: @link-color;
        }

    }
}

.toolbar-label-list {
	position: fixed;
	right: 15px;
	left: 15px;
	top: (@navbar-height + @toolbar-height + 2px);
	line-height: 1.8;

	z-index: (@zindex-navbar - 10);

	padding: 5px 15px;

    //min-height: @toolbar-height;
    color: @navbar-inverse-color;
    background-color: @navbar-inverse-bg;
    border-bottom-left-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
    border-top: 1px solid @gray-light;
    .box-shadow(@shadow-default);

    .toolbar-label {
	    margin: 0 0 0 6px;
	    //
    }
}

.toolbar-label-list-title {
	display: inline-block;
	font-size: 95%
}