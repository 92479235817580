/***************************
 * CUSTOM PRINT STYLES
 ***************************/

@media print {

	// Bootstrap Print Styles Start
	*,
    *:before,
    *:after {
        box-shadow: none !important;
        text-shadow: none !important;
    }

    // Don't show links that are fragment identifiers,
    // or use the `javascript:` pseudo protocol
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group; // h5bp.com/t
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    // Bootstrap specific changes start
    //
    // Chrome (OSX) fix for https://github.com/twbs/bootstrap/issues/11245
    // Once fixed, we can just straight up remove this.
    select {
        background: #fff !important;
    }

    // Bootstrap components
    .navbar {
        display: none;
    }

    .table {
        border-collapse: collapse !important;
    }


    // Bootstrap specific changes end
    // Bootstrap Print Styles END

	// Print Styles
    .main {
	    padding-top: 0px;
	}

	.panel-main {

    	> .panel-heading {
	    	top: 12px;

			&.tab-group {
				display: none;
			}
    	}

    	> .panel-body {
			border: 1px solid @gray-light;

			&.tab-group {
				display: none;
			}
    	}
    }

    // Activity Feed
    .activity-feed {
	    .list-group-item {

		    .feed-entry {
                .feed-icon-status {
                    //transform: translate(0px,0px);
                    .translate(0; 0);

                    &:before {
                        opacity: 1;
                    }
                }

                .feed-message {
                    margin-left: 30px;
                }
            }
        }
    }

    // medic history
    .history {
        .table-occupancy.medic-history{

            td, th {
                display: block;
                float: left;
            }

            tr:first-child > td {
                border-top: none;
            }

            .occupancy-date-from {
                width: 11%;
            }

            .occupancy-date-to {
                width: 11%;
            }

            .occupancy-menu {
                width: 5%;
            }

            .occupancy-comment {
                width: 100%;
                display: block;
                text-align: left;
			    border-top: none;
            }
        }
    }

    .overview {
		padding-top: 0px;

		.panel-accordion {

        	> .panel-heading {
	        	page-break-inside: avoid;
        	}
        }
    }

	//Print Styles Modal
	.modal.modal-print {
		position: relative;
		width: 100%;

		.modal-dialog {
			margin: 0;
			width: 100%;
		}

		.modal-header {
			//border-radius: @border-radius-large;
			left: 0;
		}

		.modal-body {
			border-radius: 0;
		}
	}

	.section-heading {
		margin-bottom: 5px;

		.section-heading-text {
			position: static;
		}
	}
}
