.activity-feed {
    .panel-body{
        padding: 0;
        max-height: 350px;
        overflow-y: scroll;
        transform: translateZ(0);
    }

    .list-group {
        margin-bottom: 0;
    }

    .list-group-item {
        position: relative;
        padding: 0;
        height: 70px;
        overflow:hidden;
        border-width: 1px 0px 0px 0px;
        margin-bottom: 0;
        transition: all 0.15s ease 0s;
        .clearfix();

        &:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-width: 0;
        }

        &:last-child {
			border-bottom-right-radius: 0;
			border-bottom-left-radius: 0;
		}

        .feed-icon {
            &:before {
                .add-icon();
                display: block;
                text-align: center;
                color: @white;
            }
        }

        .feed-entry {
            display: block;
            text-decoration: none;
            color: @text-color;
            height: 100%;

            .feed-icon-status {
                float:left;
                .translate(-25px; 0px);
                //transform: translate(-25px,0px);
                //transition: transform 0.15s ease 0.3s;
                .transition-transform(0.15s ease 0.3s);
                width: 0;

                &:before {
                    line-height: 70px;
                    font-size: @font-size-h3;
                    width: 35px;
                    opacity: 0.6;
                    background-color: @brand-primary;
                    transition: opacity 0.15s ease 0s;
                    color: #fff;
                }
            }

            .feed-message {
                padding: 5px 15px;
                margin-left: 5px;
                .transition(margin-left 0.15s ease 0.3s);
                height: 100%;

                // Keep Text from blurring
                -webkit-transform-style: preserve-3d;
				-moz-transform-style: preserve-3d;
				transform-style: preserve-3d;

                h4 {
                    margin: 0;
                    font-weight: normal;
                    color: inherit;
                }
                p {
                    margin-bottom: 0;
                    color: @gray;
                }
            }

            /*.feed-date {
                line-height: 1;
            }*/
        }

        &.feed-status-info {
            .feed-icon-status:before {
                content: '\f129';
                //background-color: @gray-light;
            }
        }

        &.feed-status-warning {
            .feed-icon-status:before {
                content: '\f12a';
                background-color: @brand-warning;
            }
        }

        &.feed-status-danger {
            .feed-icon-status:before {
                content: '\f00d';
                background-color: @brand-danger;
            }
        }

        &.feed-status-link {
	        //display: table;

            .feed-icon-status {
	            .translate(-29px; 0px);
	            &:before {
                	content: '\f0ac';
                	width: 38px;
                }
            }
            .feed-message {
	            .feed-date, > p {
		            display:none;
	            }

	            > h4 {
		            position: relative;
					top: 50%;
					.translate(0; -50%);
				}
            }
        }

        &:hover, &:focus {
            background-color: @list-group-hover-bg;

            .feed-entry {
                .feed-icon-status {
	                .translate(0px; 0px);
                    //transform: translate(0px,0px);

                    &:before {
                        opacity: 1;
                        //background-color: $brand-primary;
                    }
                }

                .feed-message {
                    margin-left: 30px;
                }
            }
        }
    }
}
