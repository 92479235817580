/***************************
 * HEADER STYLES
 ***************************/

.header {
    .header-banner {
        width: 100%;
        height: 130px;
        //background: url("../images/headerbg.jpg")  repeat-x scroll right top @gray-lighter;
    }

    .header-logo {
        width: 100%;
        height: 100%;
        background: url("../images/oeaek_logo.png") no-repeat scroll left top transparent;
    }

    .navbar-right {
	    border-left: 1px solid @navbar-default-border;
    }

    .navbar {
        margin-bottom: 0;
        .navbar-brand {
            padding: 0 @navbar-padding-horizontal;

            img, .navbar-brand-text {
                float: left;
            }

            img {
                max-height: @navbar-height;
                padding: (@navbar-padding-vertical - 3px) @navbar-padding-horizontal (@navbar-padding-vertical - 3px) 0;
                //padding:26px 8px 31px 0px
            }

            .navbar-brand-text {
                font-size: (@font-size-h3);
                padding-top: @navbar-padding-vertical;
                padding-bottom: @navbar-padding-vertical;
                padding-left: @navbar-padding-horizontal;
                height: @navbar-height;
                letter-spacing: 1px;
                text-transform: uppercase;

                border-left: 1px solid @navbar-default-border;
            }
        }

        .fa-stack {
        	margin-bottom: -3%;
			margin-top: -5%;
		}
    }

    #navbar-main-menu {
        float: left;

        .dev-system a span {
            background-color: #67b168;
            font-weight: bold;
        }

        .test-system a span {
            background-color: #842210;
            font-weight: bold;
        }

        .live-system {
            display: none;
        }

    }

    #navbar-user-menu {
        float: right;
    }
}