.inline-edit {
	display: inline-block;
	position: relative;

	.tooltip {
        opacity: @tooltip-opacity;

    }

    .btn-row {
		padding: 3px 0px;
		width: 100%;
	}

    .tooltip-inner {
	    text-align: left;



        .form-control {
            background-color: transparent;
            color: @tooltip-color;
        }

        label {
            font-weight: normal;
            display: block;
            cursor: pointer;

            span {
	            display: inline-block;
	            margin-left: 5px;
            }
        }
    }
}

.inline-edit-link, /* mh: beides ident, sinnlos,oder? */
a.inline-edit-link {
  text-decoration: none;
  color: inherit;

  &:after {
      .add-icon();
      content: @fa-var-pencil;
      font-size: 80%;
      vertical-align: 15%;
      color: inherit;
      display: none;
	  padding-left: 2px;
  }

  &:active, &:hover, &:focus {
	  color: @brand-primary;
  }
}

.inline-delete-link {
  text-decoration: none;
  color: inherit;
  padding-left: 5px;

  &:active, &:hover, &:focus {
      color: @brand-primary-light;
  }
}

.edit-mode .inline-edit-link {
  &:after {
    display: inline-block;
  }
}

