/***************************
 * CUSTOM MIXINS
 ***************************/

.add-icon() {
    display: inline-block;
    font: normal normal normal @fa-font-size-base/1 FontAwesome; // shortening font declaration
    font-size: inherit; // can't have font-size inherit on line above, so need to override
    text-rendering: auto; // optimizelegibility throws things off #1094
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //transform: translate(0, 0);
    .translate(0; 0);
}