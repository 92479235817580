.history {
	.history-form {

		label {
			display: block;
		}

		.form-group {
			margin-bottom: 0;
		}
	}

	.table-occupancy {
	    .occupancy-status {
	        width: 6%;

	        .tooltip.top-right {
		        margin-left: calc(-100% + (@tooltip-arrow-width/2));
		        margin-top: calc(-@tooltip-arrow-width/2);
			}
	    }

	    .occupancy-training-post {
			width: 40%;
		}
		
		td.occupancy-training-post {
			text-align: left;
		}

	    .occupancy-doc-num {
		    width: 12%;
	    }

	    .occupancy-doc-name {
		    width: 28%;
	    }

	    .occupancy-date-from {
		    width: 12%;
	    }

	    .occupancy-date-to {
		    width: 12%;
	    }

	    .occupancy-allocation {
	        width: 12%;
	    }

	    .occupancy-date-modified {
			width: 15%;
	    }

	    .occupancy-user-modified {
		    width: 40%;
	    }

	    .occupancy-menu {
			width: 3%;
		}

		.occupancy-comment {
			display: none;
		}
	}
	
	.active-occupancies-filter {
		text-align: right;
		margin-top: -8px;

		span.filter-label {
			color: @gray-light;
		}
		
		span.filter-label,
		a {
			margin: 0px 20px 8px 0px;
			display: inline-block;
		}
	}
}
