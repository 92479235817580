/***************************
 * GRID STYLES
 ***************************/


/*
/
/ Commented Grid Styles should not be needed anymore
/ Uncomment if not
*/

/*.grid {
    overflow: scroll;
    background-color: @table-bg;

    // Grid Header

    .ui-grid-top-panel {
        background: none;
        background-color: @brand-primary;
        color: #fff;
        font-weight: normal;
    }

    .ui-grid-filter-container {
        padding: 4px 5px;
    }

    .ui-grid-header-cell:not(:last-child) .ui-grid-vertical-bar {
        background-color: darken(@brand-primary,5%);
        padding: 1px;
    }

    .ui-grid-header {
        .ui-grid-cell-contents {
            padding: 5px;
        }
    }

    // Grid Row

    .info-sign {
        display: block;
        width: 100%;
        height: 100%;
        padding: 8px 4px;

        i:before {
            content: '\e085';
            font-size: @font-size-h4;
        }
        //color: @gray-dark;

        &:hover,
        &:focus {
            //color: @brand-primary;
        }
    }

    .btn-delete-row {
        padding: 8px 6px;
    }

    .ui-grid-row {

        .ui-grid-cell {
            background-color: transparent;
            border-right: 1px solid transparent;
            border-color: transparent;
        }

        &:nth-child(2n) {
            background-color: @table-bg-accent;

            .ui-grid-cell {
                background-color: transparent;
            }
        }

        &:hover, &:active {
            background-color: fade(@brand-primary, 10%);//@table-bg-hover;

            .ui-grid-cell {
                background-color: transparent;
            }
        }

        &.ui-grid-row-dirty {
            background-color: @state-info-bg;

            .ui-grid-cell {
                color: @state-info-text !important;
            }
        }

        &.ui-grid-row-saving {
            background-color: @gray-lighter;

            .ui-grid-cell {
                color: @gray-light !important;
            }
        }

        &.ui-grid-row-error {
            background-color: @state-danger-bg;

            .ui-grid-cell {
                color: @state-danger-text !important;
            }

            .info-sign {
                i:before {
                    content: '\e101';
                }
                color: @brand-danger;

                &:hover,
                &:focus {
                    color: darken(@brand-danger, 20%);
                }
            }
        }
    }

    // Gris Cell

    .ui-grid-cell-contents {
        padding: 8px 10px;
    }

    .ui-grid-cell-focus {
        background-color: fade(@brand-primary, 20%);
    }

    // Grid Input

    input.ui-grid-filter-input[type="text"],
    .ui-grid-cell input {
      display: block;
      width: 100%;
      //height: @input-height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
      //padding: @padding-base-vertical @padding-base-horizontal;
      //font-size: @font-size-base;
      //line-height: @line-height-base;
      color: @input-color;
      background-color: @input-bg;
      background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
      border: 1px solid @input-border;
      //border-radius: @input-border-radius;

      .input-size(@input-height-small; @padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @input-border-radius-small);

      .box-shadow(inset 0 1px 1px rgba(0,0,0,.075));
      .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");

      // Customize the `:focus` state to imitate native WebKit styles.
      .form-control-focus();

      // Placeholder
      .placeholder();

      // Disabled and read-only inputs
      //
      // HTML5 says that controls under a fieldset > legend:first-child won't be
      // disabled if the fieldset is disabled. Due to implementation difficulty, we
      // don't honor that edge case; we style them as disabled anyway.
      &[disabled],
      &[readonly],
      fieldset[disabled] & {
        cursor: @cursor-disabled;
        background-color: @input-bg-disabled;
        opacity: 1; // iOS fix for unreadable disabled content
      }

      // Reset height for `textarea`s
      textarea& {
        height: auto;
      }
    }
}*/

/* Workaround for scrambled ui-grid sort icons, do not want to install the ui-grid font. */
.ui-grid {
	.ui-grid-icon-up-dir::before {
		font-family: Fontawesome;
		content: ""; /* FA chevron-up */
	}
	.ui-grid-icon-down-dir::before {
		content: ""; /* FA chevron-down */
		font-family: Fontawesome;
	}
}