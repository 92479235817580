.modal-backdrop {
	bottom: 0;
}

.modal-dialog {
	margin-top: 50px;
}

.modal-dialog-danger {

  .modal-header {
	background-color: @state-danger-text;
	border-color: @state-danger-border;
  }

  .modal-body {
	color: @state-danger-text;
	border-top-color: @state-danger-text;
  }

}


.modal-content {
	border: 0 none;

    .box-shadow(none);
    padding-top: 50px;
    position: relative;

	dl {
	  margin-bottom: 0;
	}

	.table {
		margin-bottom: 0;
	}

	.list-group-item {
		.fa-stack-1x,
		.fa-stack-2x {
			top: -10%;
		}
	}
}

.modal-header {
    display: inline-block;

    color: white;
    background-color: @brand-primary;
    border-top-left-radius: @border-radius-base;
    border-top-right-radius: @border-radius-base;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    padding: @panel-heading-padding;

    position: absolute;
    top: 8px;
    left: 15px;
}
.modal-body {
	border-top-left-radius: @border-radius-base;
	border-top-right-radius: @border-radius-base;
	border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
	border-top: 3px solid @brand-primary;
	background-color: white;
    padding-top: 0;
    //.box-shadow(@shadow-default);
}
.modal-footer {
	background-color: white;
	//.box-shadow(@shadow-default);
	padding: @panel-heading-padding;
	border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
}

.info-box {
	padding: 4px 8px;
	margin-bottom: 0px;

	.tooltip-inner {
		text-align: left;
		  padding-left: 8px;
		  padding-right: 8px;
	}
}