.progressbar {
    display: block;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    overflow: auto;
    counter-reset: step;
    padding-top: 10px;

    li {
        list-style-type: none;
        color: @gray-light;
        text-transform: uppercase;
        font-size: @font-size-base;
        width: 33.33%;
        float: left;
        position: relative;
        margin: 0;
        padding: 0;

        &:before {
            display: block;
            content: counter(step, decimal);
            counter-increment: step;
            width: 20px;
            line-height: @line-height-base;
            color: #fff;
            background: none repeat scroll 0% 0% @gray-light;
            border-radius: @border-radius-base;
            margin: 0px auto 5px;
        }

        &:after {
            content: "";
            width: 100%;
            height: 2px;
            background: none repeat scroll 0% 0% @gray-light;
            position: absolute;
            left: -50%;
            top: 9px;
            z-index: -1;
        }

        &:first-child:after {
            display: none;
        }

        &.current {
            font-size: @font-size-large;

            &:before {
                width: 30px;
                margin-top: -3px;
            }
        }

        &.active {
            color: @brand-primary;
            &:before {
                background: none repeat scroll 0% 0% @brand-primary;
                color: #FFF;
            }
            &:after {
                background: none repeat scroll 0% 0% @brand-primary;
            }
        }
    }
}

.content-row {
    margin-left: 0;
    margin-right: 0;

    .list-group-title {
	    margin-top: 0;
	    font-weight: bold;
    }

    .list-group {
	    margin-bottom: 10px;
    }

    .list-group-item {
	    padding: 5px 10px;
    }
}

.content-row-title,
.content-row-desc {
    padding-top: 5px;
    padding-bottom: 5px;
}

.content-row-title {
    font-weight: bold;
}

.import-success-message {
	text-align: center;
	padding : 25px 0px 25px 0px;
	color: @gray-light-mid;

	i {
		color: @brand-success;
	}
}

.entryStepOne {
    form {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.entryStepTwo {
    .btn-margin-right {
        margin-right: 15px;
    }
}