.start {
	.panel-accordion {
        border: 0px none;
        .box-shadow(none);
        border-radius: @border-radius-base;

        > .panel-heading {
            padding: 0px 0px;
            background-color: @white;
        } 

        .panel-collapse {
	        > .panel-body {
				padding: 0;
				border-top: none;
            }
		}
    }
}
.orgunit-search-form {

	.orgunit-search {
		float:left;
		width: 100%;
		font-size: (@font-size-h4*0.9);
	}

    .orgunit-type-filter {
        padding: 5px 5px;
    }

    .clear {
		float: right;
		margin-left: -40px;
	}

	input::-ms-clear {
    	display: none;
	}

	legend {
		font-size: @font-size-base;
		text-transform: uppercase;
		padding-left: 2px;
		margin: 20px 0px 7px 0px;
		font-weight: 400;
		color: @gray-light-mid;
		border-bottom: 2px solid @gray-light-high;

		&.first {
			margin-top: 0;
		}
	}

	.org-search-pagination {
		margin: 0;
		width: 100%;
		text-align: center;
		font-weight: 400;

		.pagination {
			margin: 0;

			i {
				font-size: 90%;
			}
		}
	}
}
